import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Header from '../../../widgets/header';
import { Helmet } from 'react-helmet';

function TopEstateMumbai() {
  const location = useLocation();

  const currentURL = window.location.origin + location.pathname

    const Faqs = [
        {
            faqQuestion: "What services are offered by Star Estate, the top real estate broker in Mumbai?",
            faqAnswer: "The company offers residential, commercial, and luxury properties across the financial capital of India."
          },
          {
            faqQuestion: "Who are the channel partners of Star Estate in the real estate market?",
            faqAnswer: "Star Estate works with Shapoorji Pallonji, Lodha Group, Godrej Properties, Hiranandani, Piramal Realty, and many more."
          },
          {
            faqQuestion: "How can I connect with Star Estate for a property purchase consultation?",
            faqAnswer: "Get in touch with our sales manager to discover property investment opportunities."
          },
          {
            faqQuestion: "Does Star Estate work with RERA-registered developers only?",
            faqAnswer: "Star Estate works with RERA-registered developers and on RERA-approved projects only."
          },
          {
            faqQuestion: "Does Star Estate offer luxury apartments in Mumbai?",
            faqAnswer: "Yes, Star Estate offers luxury apartments in Mumbai."
          }


    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        // If the clicked item is already active, deactivate it by setting the activeIndex to null
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };


    return (
        <div>
          <Helmet>
                <title> Top Real Estate Company Mumbai, Best Broker, Consultant </title>
                <meta name="description" content='Discover excellence with star Estate - top real estate company in Mumbai. Best broker and consultant services tailored to your needs. Contact us today. ' />
                <meta name="keywords" content='Real Estate Company In Mumbai, Best Real Estate Company In Mumbai, Leading Real Estate Company In Mumbai, Top Real Estate Company In Mumbai, Real Estate Broker In Mumbai, Top Real Estate Broker In Mumbai, Leading Real Estate Broker In Mumbai, Best Real Estate Broker In Mumbai, Real Estate Consultant In Mumbai, Top Real Estate Consultant In Mumbai, Best Real Estate Consultant In Mumbai, Leading Real Estate Consultant In Mumbai ' />
                <meta name="twitter:title" content="Best Real Estate Consultancy Company in India | Star Estate"/>
                <meta name="twitter:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>   
                <meta property="og:title" content='Best Real Estate Consultancy Company in India | Star Estate'/>
                <meta property="og:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>
                <meta property="og:url" content={currentURL}/>
                <link rel="canonical" href={currentURL}/>
                <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
                <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"/>
                <script type="application/ld+json">
                    {JSON.stringify(
                    {
                      "@context": "https://schema.org",
                      "@type": "FAQPage",
                      "mainEntity": [{
                        "@type": "Question",
                        "name": "What services are offered by Star Estate, the top real estate broker in Mumbai?",
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "The company offers residential, commercial, and luxury properties across the financial capital of India."
                        }
                      },{
                        "@type": "Question",
                        "name": "Who are the channel partners of Star Estate in the real estate market?",
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "Star Estate works with Shapoorji Pallonji, Lodha Group, Godrej Properties, Hiranandani, Piramal Realty, and many more."
                        }
                      },{
                        "@type": "Question",
                        "name": "How can I connect with Star Estate for a property purchase consultation?",
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "Get in touch with our sales manager to discover property investment opportunities."
                        }
                      },{
                        "@type": "Question",
                        "name": "Does Star Estate work with RERA-registered developers only?",
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "Star Estate works with RERA-registered developers and on RERA-approved projects only."
                        }
                      },{
                        "@type": "Question",
                        "name": "Does Star Estate offer luxury apartments in Mumbai?",
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "Yes, Star Estate offers luxury apartments in Mumbai."
                        }
                    }]
                    } 
                    )}
                </script>
            </Helmet>
          <Header />
            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcSet="../assets/images/banner-emi-calculator-m.jpg" />
                    <img src="../assets/images/banner-emi-calculator.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">Top real estate company in Mumbai</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h1 className="h3 mb-0">Top real estate company in Mumbai</h1>
                    </div>



        

                        <div className="buyer-text-box mb-4">

                        <article>
            <p>
              <span className="text-green">Star Estate</span> is the best real estate broker in Mumbai with the vision to cater property investors. The organisation with sturdy bench strength dwells the best possible options for clientele. With a passion for delivering promises, the company is taking the entire property market into its stride.
            </p>
            <p>
              The company stands firm on its core values and believes in focusing on a result-driven work approach. Since its inception, the organisation has practiced a disciplined work pattern which helps it evolve in the market.
            </p>
            <p>
              With an experienced workforce, <a href="https://www.starestate.com/godrej-palm-retreat" target="_blank" rel="noopener noreferrer">Star Estate</a> is the top real estate broker in Mumbai. The continuous training about technological advancements and projects aids the organisation to grow. These are a few on the counts. Interestingly, there are many reasons behind the company’s progress in the business.
            </p>
          </article>

          <article>
            <div className="heading">
              <h2 className="h3">Star Estate | Best Real Estate Consultant in Mumbai</h2>
            </div>
            <p>Why choose Star Estate for property solutions in the city? Mumbai’s property market has numerous brokers. But, investors in the realty market need fair and personalized guidance.</p>
            <p>For this reason, Star Estate is the best real estate consultant in Mumbai. The company showcases the inventory that the client exactly is looking for. From residential apartments to commercial spheres, the company has diverse portfolios.</p>
            <p>The attentive work attitude and the habitat to listen to customer requirements make the company distinct. For the organisation, clients are equal. Be it a home buyer looking for 1BHK apartments in Mumbai, or an investor who expects to yield a high return on investment.</p>
            <p>The zest to achieve customer satisfaction makes Star Estate the best real estate consultant in Mumbai. The sales professionals at the company host investors with different property needs.</p>
          </article>

          <div className="row mx-0">
            <div className="col-md-6 mv-box px-4 pt-4">
              <section>
                <h2>Star Estate | Leading Real Estate Broker in Mumbai</h2>
                <h4>About Us</h4>
                <p>Mr. Vijay Jain laid the foundation of Star Estate 12 years ago to be the partner in the property investment journey. His leadership and guidance crafted happy homes for thousands of families in millions of sq. ft. Star Estate is the <strong>leading real estate broker in Mumbai</strong> who craves excellent performance to attain client satisfaction.</p>
                <h4>Our Presence</h4>
                <p>Star Estate is based in Mumbai and has a pan-India presence. The company operates in Mumbai, Pune, Bangalore, Chennai, Kolkata, and Gurugram. All these cities have regional offices with professional work strength to assist property investors.</p>
                <h4>Our Partners</h4>
                <p>Star Estate, a , does business with reputed developers. The company works with the tycoons in the real estate market to help investors own strong property portfolios. In Mumbai, the company works with Shapoorji Pallonji, Lodha Group, and Godrej Properties.</p>
                <p>Following giants, Star Estate is in business with Hiranandani, Piramal Realty, Kalpataru, L and T, and Risland. The list is long going forward with Raymond Realty, Oberoi Realty, and Birla Estates.</p>
                <p>Star Estate is the best real estate broker in Mumbai, as they do not ask for hidden charges. The company offers a transparent work process with the intent to deliver happy spaces.</p>

              </section>
            </div>
            <div className="col-md-6 mv-img px-0">
              <img src="https://www.starestate.in/assets/images/mumbai-2.webp" className="object-cover h-100" alt="Mission" />
            </div>
          </div>

          <div className="row mx-0 flex-row-reverse">
            <div className="col-md-6 mv-box px-4 pt-4">
              <section>
                <h3>Star Estate – Leading Real Estate Broker in Mumbai | Work Pattern</h3>
                <p> Star Estate is <strong>a leading real estate broker in Mumbai</strong>. It has a linear work pattern. The work commences by listing customer requirements, as there are many. Some look for home ownership and wealth creation. Assessing requirements and budget bracket is also considered in the process.</p>
                <p>The next step is inventory filtration, where many brands and units are shortlisted.</p>
                <p>Star Estate, the <strong>leading real estate broker in Mumbai</strong>, then plans site visits to properties. It offers clients a clear view of the current status and possession-ready quantum. </p>
                <p>Then comes the talk on the table session, where clients discuss payment plans and other charges. The final discussion takes place to seal the deal on a happy note.</p>
                <p>Finally, comes the after-sale assistance. Yes, it is an eye-opener in the market. Star Estate is the <strong>leading real estate broker in Mumbai</strong> because of it. The company offers assistance after the sale till the property investor feels at peace with the investment.  </p>
              </section>
            </div>
            <div className="col-md-6 mv-img px-0">
              <img src="https://www.starestate.in/assets/images/mumbai.webp" className="object-cover h-100" alt="Vision" />
            </div>
          </div>
        </div>  <div className="w-100 padding bg-gray-gradient-box section-faq">
                {Faqs.length > 0 && (
                    <div className="container-lg">
                        <div className="heading mx-auto text-center">
                            <h2 className="mb-0">FAQs</h2>
                        </div>
                        <div className="">
                            <div className="accordion-wrapper" id="myAccordion">
                                {Faqs.map((faq, index) => {
                                    const faqIndex = index + 1;
                                    const isActive = activeIndex === faqIndex; // Check if this item is active

                                    return (
                                        <div className="accordion-item" key={faqIndex}>
                                            <div className="accordion-header" id={`heading${faqIndex}`}>
                                                <button
                                                    className={`accordion-button ${isActive ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => handleToggle(faqIndex)}
                                                    aria-expanded={isActive ? "true" : "false"}
                                                    aria-controls={`collapse${faqIndex}`}
                                                >
                                                    <span className='text-primary'>Q{faqIndex}:</span> <div dangerouslySetInnerHTML={{ __html: faq.faqQuestion }} />
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                            <div
                                                id={`collapse${faqIndex}`}
                                                className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
                                                aria-labelledby={`heading${faqIndex}`}
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <div className="card-body">
                                                        <div>{faq.faqAnswer}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}</div></div></div></div>
          
        
    )
}
export default TopEstateMumbai