import React from 'react';

const InsightsTools = () => {
    return (
        <>
            {/* Preload images */}
            <link rel="preload" href="../assets/images/img-emi.webp" as="image" />
            <link rel="preload" href="../assets/images/img-evaluation-report.webp" as="image" />
            <link rel="preload" href="../assets/images/img-nri.webp" as="image" />
            <link rel="preload" href="../assets/images/img-realty-check.webp" as="image" />

            <div className="w-100 padding scrollto">
                <div className="container-lg">
                    <div className="heading mx-auto text-center">
                        <h3>Insights &amp; Tools</h3>
                    </div>
                    <div className="hm-project-card-wrapper">
                        <div className="row g-3">
                            <div className="col-lg-3 col-sm-6 project-card">
                                <div className="inner px-0 pt-0">
                                    <img src="../assets/images/img-emi.webp" alt="EMI Calculator" />
                                    <div className="card-footer viewmore">
                                        <a href='/emi-calculator' target="_blank" rel="noopener noreferrer">Explore Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 project-card">
                                <div className="inner px-0 pt-0">
                                    <img src="../assets/images/img-evaluation-report.webp" alt="Evaluation Report" />
                                    <div className="card-footer viewmore">
                                        <a href='/property-valuation' target="_blank" rel="noopener noreferrer">Explore Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 project-card">
                                <div className="inner px-0 pt-0">
                                    <img src="../assets/images/img-nri.webp" alt="NRI Guidelines" />
                                    <div className="card-footer viewmore">
                                        <a href='/nri-guidelines' target="_blank" rel="noopener noreferrer">Explore Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 project-card">
                                <div className="inner px-0 pt-0">
                                    <img src="../assets/images/img-realty-check.webp" alt="Reality Check" />
                                    <div className="card-footer viewmore">
                                        <a href='/reality-check-before-buying' target="_blank" rel="noopener noreferrer">Explore Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InsightsTools;
