import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './props.css';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/views/home/home';
import EmiCalculator from './components/insight-components.js/emi-calculator';
import PropertyEvaluationReport from './components/insight-components.js/property-evaluation-report';
import NriGuidelines from './components/insight-components.js/nri-guidelines';
import RealityCheck from './components/insight-components.js/reality-check-before-buying';
import AboutUs from './components/views/home/about-us';
import ContactUs from './components/views/home/contact-us';
import Career from './components/views/home/career';
import CommercialProjects from './components/projects/commercial-projects';
import CareerDetails from './components/views/home/career-details';
import AllProjects from './components/projects/all-projects';
import ProjectDetails from './components/projects/project-details-old.js';
import ScrollToTop from './scrollToTop';
import Advertisement from './components/views/home/advertisement';
import Awards from './components/views/home/awards';
import Blogs from './components/views/home/blogs';
import BlogDetails from './components/views/home/blog-details';
import News from './components/views/home/news';
import Events from './components/views/home/events';
import EventDetails from './components/views/home/eventsDetails';
import AllCities from './components/views/home/allcities';
import Builder from './components/projects/builder';
import ClientsSpeak from './components/views/home/client-speak';
import BuyerGuide from './components/views/home/buyersGuide';
import SiteMap from './components/views/home/sitemap';
import Header from './components/widgets/header';
import Footer from './components/widgets/footer';
import CPRegistration from './components/widgets/channelPartner';
import Faq from './components/views/home/faq';
import PrivacyPolicy from './components/views/home/privacy-policy';
import NewScreen from './components/views/home/disclaimer.js';
import { useEffect, useState } from 'react';
import LandingWrapper from './components/views/home/add.js';
import NotFound from './components/widgets/404NotFound.js';
import ThankYou from './components/widgets/thankyou.js';
import FooterBottom from './components/widgets/footer-bottom.js';
import TopEstateIndia from './components/views/home/top-category/india.js';
import TopEstateNoida from './components/views/home/top-category/noida.js';
import TopEstateGurgaon from './components/views/home/top-category/gurgaon.js';
import TopEstateMumbai from './components/views/home/top-category/mumbai.js';
import TopEstateChennai from './components/views/home/top-category/chennai.js';
import TopEstateBangalore from './components/views/home/top-category/bangalore.js';
import TopEstateGhaziabad from './components/views/home/top-category/ghaziabad.js';
import TopEstatePune from './components/views/home/top-category/pune.js';
import TopEstateFaridabad from './components/views/home/top-category/faridabad.js';
import TopEstateDelhi from './components/views/home/top-category/delhi.js';
import { HelmetProvider } from 'react-helmet-async';
import './responsive.css';
import NewsDetails from './components/views/home/news-details.js';
import SubCityProjects from './components/projects/subcity-projects.js';
import ProjectDetailsTest from './components/projects/project-details.js';
import TopEstateHyderabad from './components/views/home/top-category/hyderabad.js';
function AppContent() {
  const location = useLocation();

  const isProjectDetailsPage = /^\/[^/]+$/.test(location.pathname) &&
  ![
    '/emi-calculator',
    '/property-valuation',
    '/nri-guidelines',
    '/reality-check-before-buying',
    '/about-us',
    '/contact-us',
    '/careers',
    '/projects',
    '/advertisements',
    '/awards',
    '/blogs',
    '/news',
    '/events',
    '/city',
    '/clients-speak',
    '/buyer-guide',
    '/sitemap',
    '/star-partner-connect',
    '/faq',
    '/privacy-policy',
    '/disclaimer',
    '/thank-you',
    '/404NotFound'
  ].includes(location.pathname);

  const isCitySlugPage = /^\/city\/[^/]+$/.test(location.pathname);

  const [showLanding, setShowLanding] = useState(null); 

  useEffect(() => {
    const hasAccepted = sessionStorage.getItem('landingAccepted');
    if (location.pathname === '/') {
      setShowLanding(!hasAccepted);
    } else {
      setShowLanding(false);
    }
  }, [location.pathname]); 

  const handleAccept = () => {
    sessionStorage.setItem('landingAccepted', 'true');
    setShowLanding(false); 
  };

  if (showLanding === null) {
    return null; 
  }

  return (
    <>
      {showLanding ? (
        <LandingWrapper handleAccept={handleAccept} />
      ) : (
        <>
          {!isProjectDetailsPage && <Header />}
          <HelmetProvider>
            <Routes>           
            <Route path='/' element={<Home />} />
            <Route path='/emi-calculator' element={<EmiCalculator />} />
            <Route path='/property-valuation' element={<PropertyEvaluationReport />} />
            <Route path='/nri-guidelines' element={<NriGuidelines />} />
            <Route path='/reality-check-before-buying' element={<RealityCheck />} />
            <Route path='/:slugURL' element={<ProjectDetailsTest />} />
            {/* <Route path='/test/:slugURL' element={<ProjectDetailsTest />} /> */}

            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/careers' element={<Career />} />
            <Route path='/careers/:location/:id' element={<CareerDetails />} />
            <Route path='/projects/:id' element={<CommercialProjects />} />
            {/* <Route path='/cities/:id/:city' element={<SubCityProjects />} /> */}
            <Route path='/cities/:slug' element={<SubCityProjects />} />
            <Route path='/projects' element={<AllProjects />} />
            <Route path='/city' element={<AllCities />} />
            <Route path="/city/:slugURL" element={<CommercialProjects />} />
            <Route path="/builder/:slugURL" element={<Builder />} />
            <Route path="/advertisements" element={<Advertisement />} />
            <Route path="/awards" element={<Awards />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path='/blogs/:slugURL' element={<BlogDetails />} />
            <Route path="/news" element={<News />} />
            <Route path='/news/:slugURL' element={<NewsDetails />} />
            <Route path="/events" element={<Events />} />
            <Route path='/events/:slugURL' element={<EventDetails />} />
            <Route path='/city' element={<AllCities />} />
            <Route path='/clients-speak' element={<ClientsSpeak />} />
            <Route path='/buyer-guide' element={<BuyerGuide />} />
            <Route path='/sitemap' element={<SiteMap />} />
            <Route path='/star-partner-connect' element={<CPRegistration />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/disclaimer' element={<NewScreen />} /> 
            <Route path='/thank-you' element={<ThankYou />} />
            <Route path='/404NotFound' element={<NotFound />} />
            <Route path='*' element={<Navigate to="/404NotFound" />} />
            <Route path='/top-real-estate-company-in-india' element={<TopEstateIndia />} />
            <Route path='/top-real-estate-company-in-noida' element={<TopEstateNoida />} />
            <Route path='/top-real-estate-company-in-gurgaon' element={<TopEstateGurgaon />} />
            <Route path='/top-real-estate-company-in-mumbai' element={<TopEstateMumbai />} />
            <Route path='/top-real-estate-company-in-chennai' element={<TopEstateChennai />} />
            <Route path='/top-real-estate-company-in-bangalore' element={<TopEstateBangalore />} />
            <Route path='/top-real-estate-company-in-ghaziabad' element={<TopEstateGhaziabad />} />
            <Route path='/top-real-estate-company-in-pune' element={<TopEstatePune />} />
            <Route path='/top-real-estate-company-in-faridabad' element={<TopEstateFaridabad />} />
            <Route path='/top-real-estate-company-in-delhi' element={<TopEstateDelhi />} />
            <Route path='/top-real-estate-company-in-hyderabad' element={<TopEstateHyderabad />} />
          </Routes>
          </HelmetProvider>
          {(isCitySlugPage) ? <FooterBottom /> :(isProjectDetailsPage) ? '' : <Footer />}
        </>
      )}
    </>
  );
}

function App() {
  return (
    <BrowserRouter basename='/'>
      <ScrollToTop />
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
