import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Header from '../../../widgets/header';
import { Helmet } from 'react-helmet';

function TopEstateIndia() {
    const location = useLocation();

  const currentURL = window.location.origin + location.pathname

    return (
        <div>
            <Helmet>
                <meta name="twitter:title" content="Best Real Estate Consultancy Company in India | Star Estate"/>
                <meta name="twitter:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>   
                <meta property="og:title" content='Best Real Estate Consultancy Company in India | Star Estate'/>
                <meta property="og:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>
                <meta property="og:url" content={currentURL}/>
                    <link rel="canonical" href={currentURL}/>
                    <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
                    <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"/>
            </Helmet>
            <Header />
            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcSet="../assets/images/banner-emi-calculator-m.jpg" />
                    <img src="../assets/images/banner-emi-calculator.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">Top real estate company in India</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h1 className="h3 mb-0">Top real estate company in India</h1>
                    </div>



        

                        <div className="buyer-text-box mb-4">

                            <article>
                                <p>
                                    <span className="fa-2x">S</span>tar Estate, today's <strong>top real estate Company in India</strong>, laid its foundation 12 years back. Since the start, the organization has been correcting the purchase journey for countless clients. Our vision is to bifurcate property buyers linearly. It offers us the ease of exhibiting property variants in a budget-friendly manner. What makes us stand out from the crowded Indian property market is our vision. We aim to deliver the best-in-class buyer experience to fellow citizens. The result-oriented work approach and the aim to host happiness make <a href="https://www.starestate.com/" target="_blank" rel="noopener noreferrer">Star Estate</a> the <strong>best real estate broker in India</strong>.
                                </p>
                                <p>
                                    We aim to bring the best of both worlds to enjoy premium investment. For this, the business tactics are tailored to meet clients' requirements. To address each property request, we have a bandwagon of professionals. From back-end support to CRM management and after-sale assistance, everything is aligned. It all makes Star Estate a leading real estate consultant in India.
                                </p>
                                </article>
                        <div className="row mx-0">
                            <div className="col-md-6 mv-box px-4 pt-4">
                                <section>
                                    <h6>
                                        BEST REAL ESTATE BROKER IN INDIA | <span className="text-green">STAR ESTATE</span>
                                    </h6>
                                    <p className="text-justify text-sm-left">
                                        Star Estate, the <strong>best real estate consultant in India</strong>, has trained professionals aiming to perform with perfection. Our go-getter approach gives sleepless nights to competitors. While the same attitude offers peace of mind to clients. Headquartered in Noida, we are a team of 2000+ professionals. Our offices in Bangalore, Pune, Gurgaon, and Agra ensure pan-India presence. Star Estate is boundless and flourishes with happy families. The thought of partnering in building legacy pushes us to perform better.
                                    </p>
                                    <p className="text-justify text-sm-left">
                                        Star Estate, a <strong>top real estate broker in India</strong>, rejoices in having residential, commercial, and mixed-use properties on the offering deck. With pre-lease retail shops, lockable office spaces, studio apartments, and independent floors, we offer everything property buyers ask for. We do share detailed information to help clients make a wise investment. We are a team of innovators who always try to exhibit inventory in an easily understandable manner. Star Estate, the <strong>best real estate Company in India</strong>, has advanced technology support to tackle property buying queries. Our experts take pride in addressing clients' apprehensions with utmost priority.
                                    </p>
                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0" style={{ paddingLeft: '30px' }}>
                                <img
                                    src="https://www.starestate.in/assets/images/top-real-estate-company-in-india1.webp"
                                    className="object-cover h-100"
                                    alt="Mission"
                                />
                            </div>
                        </div>

                        <div className="row mx-0 flex-row-reverse">
                            <div className="col-md-6 mv-box px-4 pt-4">
                                <section>
                                    <h6>Why Star Estate is Leading Real Estate Consultant in India</h6>
                                    <ul className="list ml-3 mb-3 text-justify text-sm-left">
                                        <li>
                                            <strong>Researched Practice</strong> - Each aspiring property owner matters to us. We leave no stone unturned to assist them with perfect inventory. Along with the property option comes quality assurance, where we ensure after-sale assistance.
                                        </li>
                                        <li>
                                            <strong>Technology-Driven Assistance</strong> - Star Estate, the leading real estate company in India, is backed by AI (Artificial Intelligence) and VR (Virtual Reality). Their deadly combination garners precise information to cater to the property buyers. It maintains records, data, and patterns and helps us to build executive strategies.
                                        </li>
                                        <li>
                                            <strong>Choicest Properties</strong> - Brainstorming isn't the word for today. With Star Estate, the best real estate broker in India, explore the best properties anywhere in India. Our sales team is the guiding light for buyers and investors considering budget limitations.
                                        </li>
                                        <li>
                                            <strong>Promising Possibilities</strong> - Star Estate, the <strong>best real estate consultant in India</strong>, amplifies property option discoveries. Explore from 2BHK apartments to 6-bedroom condominiums and secure comfort yet luxurious residences.
                                        </li>
                                        <li>
                                            <strong>Futuristic Approach</strong> - To cater to the investor segment, we have a string of options. From locked offices to co-working pods, retail shops, kiosks, and more. Star Estate, the <strong>best real estate consultant in India</strong>, applauds premium properties that promise high returns.
                                        </li>
                                    </ul>
                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0">
                                <img
                                    src="https://www.starestate.in/assets/images/top-real-estate-company-in-india2.webp"
                                    className="object-cover h-100"
                                    alt="Vision"
                                />
                            </div>
                        </div>

                        <p className="mt-2 text-justify text-sm-left">Star Estate, the leading real estate company in India, fulfils the property purchase dream seamlessly. The tech and 24x7 support promises easy and safe investment. </p>


                    </div>
                </div>
                                     </div>
          
        </div>
    )
}
export default TopEstateIndia