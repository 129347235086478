import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import NotFoundImage from './404NotFound.webp';
import { Helmet } from 'react-helmet';
const NotFound = () => {
  const location = useLocation();

  const currentURL = window.location.origin + location.pathname
  return (
    <>
     <Helmet>
                    <title>Best Real Estate Consultancy Company in India | Star Estate</title>
                    <meta name="description" content="Star Estate is the Best Real Estate Consultancy Company In India. Browse Extensive Range Of Luxury Residential and Commercial Properties in India. Explore Now." />
                    <meta name="keywords" content="Real Estate Consultancy Company, Real Estate Consultancy Company in India, Best Real Estate Consultancy,  Real Estate Company, Real Estate company in India, " />
                    <meta name="twitter:title" content="Best Real Estate Consultancy Company in India | Star Estate"/>
                    <meta name="twitter:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>   
                    <meta property="og:title" content='Best Real Estate Consultancy Company in India | Star Estate'/>
                    <meta property="og:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>
                    <meta property="og:url" content={currentURL}/>
                    <link rel="canonical" href={currentURL}/>
                    <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
                    <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"/>
                </Helmet>
    
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light text-center">
      <h1 className="display-1" style={{ color: '#F7941E' }}>404</h1>
      <p className="lead" style={{ color: '#333' }}>
        Oops! The page you are looking for does not exist.
      </p>
      <Link to="/" className="btn btn-warning btn-lg" style={{ marginTop: '20px', marginBottom: '20px' }}>
        Go to Home
      </Link>
    
        <img 
          src={NotFoundImage}
          alt="404 Illustration" 
          style={{ maxWidth: '20%', height: 'auto' }} 
        />
      
    </div></>
  );
};

export default NotFound;
