import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Header from '../../../widgets/header';
import { Helmet } from 'react-helmet';

function TopEstateChennai() {
  const location = useLocation();
  
    const currentURL = window.location.origin + location.pathname

    const Faqs = [
        { faqQuestion: 'What is Star Estate?', faqAnswer: 'Star Estate is a leading real estate agent in Chennai, working closely with reputed developers.' },
        { faqQuestion: 'Is Star Estate RERA-registered?', faqAnswer: 'Yes, Star Estate is a RERA-registered company with rights to operate in Chennai.' },
        { faqQuestion: 'Does Star Estate operate in Chennai?', faqAnswer: 'The company primarily operates in Perungalathur, Manapakkam, Anna Nagar, and Medavakkam.' },
        { faqQuestion: 'How can I connect with Star Estate?', faqAnswer: 'Get in touch with our sales manager to explore property options.' },
        { faqQuestion: 'Which developers does Star Estate work with?', faqAnswer: 'In Chennai, Star Estate collaborates with TVS Emerald.' },
        { faqQuestion: 'Does Star Estate deal in luxury property?', faqAnswer: 'Yes, Star Estate offers luxury properties across different sizes and budgets.' },
        { faqQuestion: 'Does Star Estate offer after-sale assistance?', faqAnswer: 'Yes, the company provides after-sale assistance, which is part of its unique service.' }
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        // If the clicked item is already active, deactivate it by setting the activeIndex to null
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };


    return (
        <div>
           <Helmet>
                <title> Top Real Estate Company Chennai, Best Broker, Consultant  </title>
                <meta name="description" content="Discover your real estate journey with Chennai's top company. Trusted brokers and consultants ensure seamless transactions and expert guidance. Contact best agent."  />
                <meta name="keywords" content='Real Estate Company In Chennai, Best Real Estate Company In Chennai, Leading Real Estate Company In Chennai, Top Real Estate Company In Chennai, Real Estate Broker In Chennai, Top Real Estate Broker In Chennai, Real Estate Agents In Chennai, Best Real Estate Broker In Chennai, Real Estate Consultant In Chennai, Top Real Estate Consultant In Chennai, Best Real Estate Consultant In Chennai, Leading Real Estate Consultant In Chennai ' />
                <meta name="twitter:title" content="Best Real Estate Consultancy Company in India | Star Estate"/>
                <meta name="twitter:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>   
                <meta property="og:title" content='Best Real Estate Consultancy Company in India | Star Estate'/>
                <meta property="og:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>
                <meta property="og:url" content={currentURL}/>
                    <link rel="canonical" href={currentURL}/>
                    <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
                    <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"/>
            </Helmet>
          <Header />
            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcSet="../assets/images/banner-emi-calculator-m.jpg" />
                    <img src="../assets/images/banner-emi-calculator.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">Top real estate company in Chennai</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h1 className="h3 mb-0">Top real estate company in Chennai</h1>
                    </div>



        

                        <div className="buyer-text-box mb-4">

                       
          <article>
            <p className='mb-4'>
              Chennai, the capital of Tamil Nadu, is home to hundreds of aspiring property investors. Star Estate, the{' '}
              <strong>top real estate company in Chennai</strong>, is there for them. 12 years back, Mr. Vijay Jain, a
              real estate enthusiast, laid the foundation of Star Estate. He envisions paving the way for property
              investors with a guarded approach to fair business practices.
            </p>
            <p className='mb-4'>
              <a href="https://www.starestate.com/">Star Estate</a>, the{' '}
              <strong>leading real estate company in Chennai</strong>, believes in a transparent work mechanism. It
              benefits both the company and clients: the company earns credibility while clients get the best property
              deals.
            </p>
            <p>Both ways, it’s a win-win situation, making more investors seek property search and guidance from industry giants.</p>
          </article>
         
            <div className="heading">
              <h2 className="h3">Star Estate Work Ethos | Top Real Estate Broker in Chennai</h2>
            </div>
            <ul className="list mb-0">
              <li>
                <strong>Transparency</strong> - Listening is the key to success, and our team patiently listens to the client's requirements and expectations. With it, we begin the procedure of listing, searching, and shortlisting properties for clients. The process includes picking up budget-friendly inventory and describing the project embellished with features that make the dream living experience a fairy-tale.
              </li>
              <li>
                <strong>Reliability</strong> - The transparent work approach, we have won the trust of clients' across segments. Thus, clienteles refer us to friends and family as they know Star Estate is a reliable name in the Indian real estate market. A fair deal promise make Star Estate, top real estate broker in Chennai.
              </li>
              <li>
                <strong>Sincerity</strong> - We stay true to our commitments. Our professional approach and precision
                in meeting client expectations have established our proven track record since inception.
              </li>
              <li>
                <strong>Assurance</strong> - Star Estate assures investors about closing the right deal. The company addresses property investment purposes in the wake to meet client satisfaction. It exhibits relevant properties for investors with assurance of post-sale. Hence, Star Estate becomes the top real estate broker in Chennai.
              </li>
            </ul>
          
          <div className="row mx-0">
            <div className="col-md-6 mv-box px-4 pt-4">
              <section>
                <div className="heading text-left">
                  <h3>Star Estate | Leading Real Estate Consultant in Chennai | Meet Our Patron</h3>
                </div>
                <p>
                TVS Emerald is a prominent name in Chennai’s property market. Star Estate and TVS work collectively to embark property buyers on a seamless journey. With a wide range of residential options, it offers an exquisite living space that adds to the asset side of the balance sheet in life.
                </p>
              </section>
            </div>
            <div className="col-md-6 mv-img px-0">
              <img src="https://www.starestate.in/assets/images/pune-2.webp" className="object-cover h-100" alt="Mission" />
            </div>
          </div>
        </div>
        <div className="w-100 padding bg-gray-gradient-box section-faq">
                {Faqs.length > 0 && (
                    <div className="container-lg">
                        <div className="heading mx-auto text-center">
                            <h2 className="mb-0">FAQs</h2>
                        </div>
                        <div className="">
                            <div className="accordion-wrapper" id="myAccordion">
                                {Faqs.map((faq, index) => {
                                    const faqIndex = index + 1;
                                    const isActive = activeIndex === faqIndex; // Check if this item is active

                                    return (
                                        <div className="accordion-item" key={faqIndex}>
                                            <div className="accordion-header" id={`heading${faqIndex}`}>
                                                <button
                                                    className={`accordion-button ${isActive ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => handleToggle(faqIndex)}
                                                    aria-expanded={isActive ? "true" : "false"}
                                                    aria-controls={`collapse${faqIndex}`}
                                                >
                                                    <span className='text-primary'>Q{faqIndex}:</span> <div dangerouslySetInnerHTML={{ __html: faq.faqQuestion }} />
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                            <div
                                                id={`collapse${faqIndex}`}
                                                className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
                                                aria-labelledby={`heading${faqIndex}`}
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <div className="card-body">
                                                        <div>{faq.faqAnswer}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}</div></div></div></div>
          
        
    )
}
export default TopEstateChennai