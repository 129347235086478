import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { Helmet } from "react-helmet";

export const fetchCities = async () => {
    try {
        const response = await axiosInstance.get(`/city/getCities`);
        const filteredCity = response.data.filter(city => city.status === true);
        return filteredCity;
    } catch (error) {
        // console.error('Error fetching cities:', error);
        // throw error;
    }
};

export const fetchAllProjects = async () => {
    try {
        const response = await axiosInstance.get(`/addProjects/getProject`);
        return response.data;
    } catch (err) {
        // console.error('Unexpected error:', err);
        // throw err;
    }
};

export const fetchDevelopers = async () => {
    try {
        const response = await axiosInstance.get(`/developers/getDeveloper`);
        const filteredDeveloper = response.data.filter(developer => developer.status === true);
        return filteredDeveloper;
    } catch (error) {
        // console.error('Error fetching developers:', error);
        // throw error;
    }
};

export const fetchSubCity = async (slug) => {
    try {
        const response = await axiosInstance.get(`/subCity/getSubCityByCity/${slug}`)
        const filteredResponse = response.data.filter(adv => adv.status === true);
        return filteredResponse;
    } catch {}
}

export default function SiteMap() {
    const [cities, setCities] = useState([]);
    const [SubCity, setSubCity] = useState([]);
    const [developers, setDevelopers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [news, setNews] = useState([]);

    const navigate = useNavigate()
    const location = useLocation();

  const currentURL = window.location.origin + location.pathname

    useEffect(() => {
        const getCities = async () => {
            try {
                const data = await fetchCities();
                const sortedData = data.sort((a, b) => a.location.trim().localeCompare(b.location.trim()));
                setCities(sortedData);
                
                const allSubCities = await Promise.all(
                    sortedData.map(async (city) => {
                        const subCitiesData = await fetchSubCity(city.slugURL);
                        return { city: city.slugURL, subCities: subCitiesData };
                    })
                );
                setSubCity(allSubCities);
                // console.log('subcity', allSubCities)
            } catch (error) {
                // console.error('Error fetching cities:', error);
            }
        };
        getCities();
    }, []);

    useEffect(() => {
        const getDevelopers = async () => {
            try {
                const data = await fetchDevelopers();
                const sortedData = data.sort((a, b) => a.developerName.trim().localeCompare(b.developerName.trim()));
                setDevelopers(sortedData);
            } catch (error) {
                // console.error('Error fetching developers:', error);
            }
        };
        getDevelopers();
    }, []);

    useEffect(() => {
        const getAllProjects = async () => {
            try {
                const data = await fetchAllProjects();
                setProjects(data);
            } catch (error) {
                // console.error('Error fetching projects:', error);
            }
        };
        getAllProjects();
    }, []);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axiosInstance.get('/blogs/getBlog');
                const filteredBlogs = response.data.filter(blog => blog.status === true && blog.blogsCategory === 'blog');
                const filteredNews = response.data.filter(blog => blog.status === true && blog.blogsCategory === 'news');
                setBlogs(filteredBlogs);
                setNews(filteredNews);
            } catch (error) {
            }
        };
        fetchBlogs();
    }, []);

    useEffect(() => {
        const hasDropChildElements = document.querySelectorAll('.hasDropChild');
        const handleClick = function () {
            const dropdowns = document.querySelectorAll('.hasDropChild .dropdown');
            dropdowns.forEach(dropdown => {
                dropdown.style.display = 'none';
            });
            const dropdown = this.querySelector('.dropdown');
            if (dropdown.style.display === 'none') {
                dropdown.style.display = 'block';
            } else {
                dropdown.style.display = 'none';
            }
        };
        hasDropChildElements.forEach(element => {
            element.addEventListener('click', handleClick);
        });
        return () => {
            hasDropChildElements.forEach(element => {
                element.removeEventListener('click', handleClick);
            });
        };
    }, []);


    const propertyTypes = [
        { type: "2BHK", labels: ["2 BHK Projects", "2 BHK Flats", "2 BHK Property", "2 BHK Apartments"] },
        { type: "3BHK", labels: ["3 BHK Projects", "3 BHK Flats", "3 BHK Property", "3 BHK Apartments"] },
        { type: "4BHK", labels: ["4 BHK Projects", "4 BHK Flats", "4 BHK Property", "4 BHK Apartments"] },
        { type: "5BHK", labels: ["5 BHK Projects", "5 BHK Flats", "5 BHK Property", "5 BHK Apartments"] },
        { type: "FLATS", labels: ["FLATS"] },
        { type: "APARTMENTS", labels: ["APARTMENTS"] },
        { type: "PROJECTS", labels: ["NEW PROJECTS"] },
        { type: "COMMERCIAL PROPERTY", labels: ["COMMERCIAL PROPERTY"] },
    ];

    const handleLinkClick = (type, label, city) => {
        const slug = `${label.trim().toLowerCase().replace(/\s+/g, '-')}-in-${city.trim().toLowerCase().replace(/\s+/g, '-')}`;
        return slug;
    };

    return (
        <>
                <Helmet>
                    <title>Best Real Estate Consultancy Company in India | Star Estate</title>
                    <meta name="description" content='Discover insights from Star Estate.' />
                    <meta name="keywords" content='real estate, blog, insights' />
                    <meta name="twitter:title" content="Best Real Estate Consultancy Company in India | Star Estate"/>
                    <meta name="twitter:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>   
                    <meta property="og:title" content='Best Real Estate Consultancy Company in India | Star Estate'/>
                    <meta property="og:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>
                    <meta property="og:url" content={currentURL}/>
                    <link rel="canonical" href={currentURL}/>
                    <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
                    <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"/>
                </Helmet>
                <div className="emptyBox"></div>
                <div className="w-100">
                    <div className="container-lg">
                        <div className="breadcrumbContainer" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item active">Sitemap</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="w-100 padding">
                    <div className="container-lg">
                        <div className="heading mx-sm-auto text-sm-center">
                            <h1 className="h3 mb-0">Sitemap</h1>
                        </div>
                        <div className="readmore mt-0 mb-4 w-100">
                            <Link to="/" className="button w-100">Home</Link>
                        </div>
                        <div className="sitemapContainer">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 sitemapBox">
                                    <ul className="list">
                                        <li><Link to="/about-us">About Us</Link></li>
                                        <li><Link to="/clients-speak">Client's Speak</Link></li>
                                        <li><Link to="/careers">Careers</Link></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-4 col-sm-6 sitemapBox">
                                    <ul className="list">
                                        <li><Link to="/faq">FAQs</Link></li>
                                        <li><Link to="/buyer-guide">Buyer Guide</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        {/* <li><Link to="/sitemap">Sitemap</Link></li> */}
                                    </ul>
                                </div>
                                <div className="col-lg-4 col-sm-6 sitemapBox">
                                    <ul className="list">
                                        <li><b>Media</b></li>
                                        <ul className="list">
                                            <li><Link to="/awards">Awards</Link></li>
                                            <li><Link to="/news">News</Link></li>
                                            <li><Link to="/events">Events</Link></li>
                                            <li><Link to="/blogs">Blogs</Link></li>
                                            <li><Link to="/advertisements">Advertisements</Link></li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Cities Section */}
                        <div className="sitemapContainer">
                            <div className="heading">
                                <h6 className="mb-0 text-primary">Our Cities</h6>
                            </div>
                            <div className="sitemapBox">
                                <div className="readmore d-flex mt-0 flex-wrap w-100">
                                    {cities.length > 0 ? (
                                        cities.map((city) => (
                                            <Link key={city._id} to={`/city/${city.slugURL}`} className="button gray">
                                                {city.location}
                                            </Link>
                                        ))
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <span className="ml-2">Loading...</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Developers Section */}
                        <div className="sitemapContainer">
                            <div className="heading">
                                <h6 className="mb-0 text-primary">Our Builders</h6>
                            </div>
                            <div className="sitemapBox">
                                <div className="readmore d-flex mt-0 flex-wrap w-100">
                                    {developers.length > 0 ? (
                                        developers.map((developer) => (
                                            <Link key={developer._id} to={`/builder/${developer.slugURL}`} className="button gray">
                                                {developer.developerName}
                                            </Link>
                                        ))
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <span className="ml-2">Loading...</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="sitemapContainer">
                            <div className="heading">
                                <h6 className="mb-0 text-primary">Our Projects</h6>
                            </div>
                            <div className="sitemapBox">
                                <div className="readmore d-flex mt-0 flex-wrap w-100">
                                    {/* <Link to="/projects/luxury" className="button gray">Luxury</Link> */}
                                    <Link to="/projects/residential" className="button gray">Residential</Link>
                                    <Link to="/projects/commercial" className="button gray">Commercial</Link>
                                    <Link to="/projects/new-launch" className="button gray">New Launches</Link>
                                    
                                </div>
                            </div>
                        </div>
                        {/* Studio Apartments */}
                        <div className="sitemapContainer">
                            <div className="heading">
                                <h6 className="mb-0 text-primary">Studio Apartments</h6>
                            </div>
                            <div className="sitemapBox">
                                <div className="readmore d-flex mt-0 flex-wrap w-100">
                                    {cities.length > 0 ? (
                                        cities.map((city) => (
                                            <Link
                                                key={city._id}
                                                to={`/projects/${handleLinkClick('studio-apartments', 'Studio Apartments', city.location)}`}
                                                className="button gray"
                                            >
                                                {city.location}
                                            </Link>
                                        ))
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center w-100">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <span className="ml-2">Loading...</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* {Villas by city} */}
                        <div className="sitemapContainer">
                            <div className="heading">
                                <h6 className="mb-0 text-primary">Villas</h6>
                            </div>
                            <div className="sitemapBox">
                                <div className="readmore d-flex mt-0 flex-wrap w-100">
                                    {cities.length > 0 ? (
                                        cities.map((city) => (
                                            <Link
                                                key={city._id}
                                                to={`/projects/${handleLinkClick('villas', 'Villas', city.location)}`}
                                                className="button gray"
                                            >
                                                {city.location}
                                            </Link>
                                        ))
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center w-100">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <span className="ml-2">Loading...</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Plots by City */}
                        <div className="sitemapContainer">
                            <div className="heading">
                                <h6 className="mb-0 text-primary">Plots</h6>
                            </div>
                            <div className="sitemapBox">
                                <div className="readmore d-flex mt-0 flex-wrap w-100">
                                    {cities.length > 0 ? (
                                        cities.map((city) => (
                                            <Link
                                                key={city._id}
                                                to={`/projects/${handleLinkClick('plots', 'Plots', city.location)}`}
                                                className="button gray"
                                            >
                                                {city.location}
                                            </Link>
                                        ))
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center w-100">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <span className="ml-2">Loading...</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Property by city */}
                        <div className="sitemapContainer">
                            {cities.map((city) => (
                                <div key={city._id} className="mb-4">
                                    {/* City Heading */}
                                    <div className="heading">
                                        <h3>{city.location}</h3>
                                    </div>

                                    {/* Property Types */}
                                    {propertyTypes.map((property) => (
                                        <div className="sitemapBox" key={property.type}>
                                            <div className="heading">
                                                <h6 className="mb-0 text-primary">{property.type}</h6>
                                            </div>
                                            <div className="readmore d-flex mt-0 flex-wrap w-100">
                                                {property.labels.map((label, index) => (
                                                    <Link
                                                        key={index}
                                                        to={`/projects/${handleLinkClick(property.type, label, city.location)}`}
                                                        className="button gray"
                                                    >
                                                        {label} in {city.location}
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    ))}

                                    {/* Sub-Cities Section */}
                                    {SubCity.map((subCityObj, subCityIndex) =>
                                        subCityObj.city === city.slugURL && subCityObj.subCities?.length > 0 ? (
                                            <div className="mb-4" key={subCityIndex}>
                                                <div className="sitemapBox">
                                                    <div className="heading">
                                                        <h6 className="mb-0 text-primary">Sub-Cities in {city.location}</h6>
                                                    </div>
                                                    <div className="readmore d-flex flex-wrap w-100 mt-3">
                                                        {/* Column 1: Flats */}
                                                        <div className="column mx-2">
                                                            {subCityObj.subCities.map((subCity, subCityInnerIndex) => (
                                                                <Link
                                                                    key={`flat-${subCityInnerIndex}`}
                                                                    to={`/cities/${handleLinkClick("FLATS", "FLATS", subCity.slugURL)}-${subCity.city}`}
                                                                    className="button gray mb-2"
                                                                >
                                                                    Flats in {subCity.sub_city}
                                                                </Link>
                                                            ))}
                                                        </div>

                                                        {/* Column 2: Apartments */}
                                                        <div className="column mx-2">
                                                            {subCityObj.subCities.map((subCity, subCityInnerIndex) => (
                                                                <Link
                                                                    key={`apartment-${subCityInnerIndex}`}
                                                                    to={`/cities/${handleLinkClick("APARTMENTS", "APARTMENTS", subCity.slugURL)}-${subCity.city}`}
                                                                    className="button gray mb-2"
                                                                >
                                                                    Apartments in {subCity.sub_city}
                                                                </Link>
                                                            ))}
                                                        </div>

                                                        {/* Column 3: New Projects */}
                                                        <div className="column mx-2">
                                                            {subCityObj.subCities.map((subCity, subCityInnerIndex) => (
                                                                <Link
                                                                    key={`newproject-${subCityInnerIndex}`}
                                                                    to={`/cities/${handleLinkClick("PROJECTS", "NEW PROJECTS", subCity.slugURL)}-${subCity.city}`}
                                                                    className="button gray mb-2"
                                                                >
                                                                    New Projects in {subCity.sub_city}
                                                                </Link>
                                                            ))}
                                                        </div>

                                                        {/* Column 4: Residential Properties */}
                                                        <div className="column mx-2">
                                                            {subCityObj.subCities.map((subCity, subCityInnerIndex) => (
                                                                <Link
                                                                    key={`residential-${subCityInnerIndex}`}
                                                                    to={`/cities/${handleLinkClick("RESIDENTIAL", "RESIDENTIAL PROPERTY", subCity.slugURL)}-${subCity.city}`}
                                                                    className="button gray mb-2"
                                                                >
                                                                    Residential Property in {subCity.sub_city}
                                                                </Link>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    )}

                                </div>
                            ))}
                        </div>


                        {/* ----------------------- */}
                        <div className="sitemapBox sitemapContainer">
                            <div className="readmore d-flex mt-0 flex-wrap w-100">
                                <Link className="button gray" to='/top-real-estate-company-in-india'>Top Real Estate Company in India</Link>
                                <Link className="button gray" to='/top-real-estate-company-in-noida'>Top Real Estate Company in Noida</Link>
                                <Link className="button gray" to='/top-real-estate-company-in-gurgaon'>Top Real Estate Company in Gurugram</Link>
                                <Link className="button gray" to='/top-real-estate-company-in-mumbai'>Top Real Estate Company in Mumbai</Link>
                                <Link className="button gray" to='/top-real-estate-company-in-chennai'>Top Real Estate Company in Chennai</Link>
                                <Link className="button gray" to='/top-real-estate-company-in-bangalore'>Top Real Estate Company in Bangalore</Link>
                                <Link className="button gray" to='/top-real-estate-company-in-ghaziabad'>Top Real Estate Company in Ghaziabad</Link>
                                <Link className="button gray" to='/top-real-estate-company-in-delhi'>Top Real Estate Company in Delhi</Link>
                                <Link className="button gray" to='/top-real-estate-company-in-pune'>Top Real Estate Company in Pune</Link>
                                <Link className="button gray" to='/top-real-estate-company-in-faridabad'>Top Real Estate Company in Faridabad</Link>
                                <Link className="button gray" to='/top-real-estate-company-in-hyderabad'>Top Real Estate Company in Hyderabad</Link>
                            </div>
                        </div>
                        {/* ----------------------- */}

                        <div className="sitemapContainer sitemapBox">
                            <div className="heading">
                                <h6 className="mb-0 text-primary">Our Residential Projects</h6>
                            </div>

                            <div className="sitemapBox"  >
                              
                            <div className="readmore d-flex mt-0 flex-wrap w-100" >
                            {projects.filter((adv) => adv.status === true && adv.property_type === 'residential').map((project , index) => (          
                                        <Link to={`/${project.slugURL}`} target="_blank" className="button gray" key={project._id}>{project.projectName}</Link>
                                   ))}

                                </div>
                            </div> 
                        </div>

                        <div className="sitemapContainer sitemapBox">
                            <div className="heading">
                                <h6 className="mb-0 text-primary">Our Commercial Projects</h6>
                            </div>

                            <div className="sitemapBox"  >
                              
                            <div className="readmore d-flex mt-0 flex-wrap w-100" >
                            {projects.filter((adv) => adv.status === true && adv.property_type === 'commercial').map((project , index) => (          
                                        <Link to={`/${project.slugURL}`} className="button gray" target="_blank" key={project._id}>{project.projectName}</Link>
                                   ))}

                                </div>
                            </div> 
                        </div>

                        <div className="sitemapContainer sitemapBox">
                            <div className="heading">
                                <h6 className="mb-0 text-primary">Our Blogs</h6>
                            </div>

                            <div className="sitemapBox"  >
                              
                            <div className="readmore d-flex mt-0 flex-wrap w-100" >
                            {blogs.filter((adv) => adv.status === true).map((blog , index) => (          
                                        <Link to={`/blogs/${blog.slugURL}`} className="button gray" target="_blank" key={blog._id}>{blog.blogsName}</Link>
                                   ))}

                                </div>
                            </div> 
                        </div>

                        <div className="sitemapContainer sitemapBox">
                            <div className="heading">
                                <h6 className="mb-0 text-primary">Our News</h6>
                            </div>

                            <div className="sitemapBox"  >
                              
                            <div className="readmore d-flex mt-0 flex-wrap w-100" >
                            {news.filter((adv) => adv.status === true).map((blog, index) => (          
                                        <Link to={`/news/${blog.slugURL}`} className="button gray" target="_blank" key={blog._id}>{blog.blogsName}</Link>
                                   ))}

                                </div>
                            </div> 
                        </div>
                    </div>
                </div>

        </>
    );
}
