import axios from 'axios';
import axiosInstance from '../components/views/utils/axiosInstance';

// const API_BASE_URL = `http://localhost:4000`;
// const API_BASE_URL = `https://ecis.in/apis/star-estate-API`;


export const fetchCategories = async () => {
    try {
        const response = await axiosInstance.get(`/categories/getCategories`);
        return response.data;
    } catch (error) {
        // console.error('Error fetching categories:', error);
        // throw error;
    }
};

export const fetchCities = async () => {
    try {
      const response = await axiosInstance.get(`/city/getCities`);
      const cities = response.data.filter(city => city.status === true); 
      return cities;
    } catch (error) {
    //   console.error('Error fetching cities:', error);
    //   throw error;
    }
  };
  

export const fetchProjects = async (id) => {
    try {
        const response = await axiosInstance.get(`/addProjects/getProjectByType/${id}`);
        return response.data;
    } catch (err) {
        // console.error('Unexpected error:', err);
        // throw err;
    }
};

export const fetchProjectsByConfig = async (slug) => {
    try {
        const response = await axiosInstance.get(`/addProjects/projectsConfig/${slug}`);
        // console.log(response.data)
        return response.data;
    } catch (err) {
        // console.error('Unexpected error:', err);
        // throw err;
    }
};

export const fetchProjectsBySubCity = async (slug, city) => {
    try {
        const response = await axiosInstance.get(`/addProjects/projectByAddress/${slug}/${city}`);
        return response.data;
    } catch (err) {
        // console.error('Unexpected error:', err);
        // throw err;
    }
};


