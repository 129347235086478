import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from '../views/utils/axiosInstance';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchCities, fetchProjectsBySubCity } from '../../apis/home-page-api';
import { Helmet } from 'react-helmet';

const useCustomParams = () => {
    const { slug } = useParams();
  
    if (!slug) return { id: null, city: null };
  
    const lastHyphenIndex = slug.lastIndexOf("-");
    const id = slug.substring(0, lastHyphenIndex); 
    const city = slug.substring(lastHyphenIndex + 1); 
  
    return { id, city };
  };


function SubCityProjects() {
    const [commercialProjects, setCommercialProjects] = useState([]);;
    const [configurationDetails, setConfigurationDetails] = useState([]);
    const [meta_title, setMetaTitle] = useState('');
    const [meta_keyword, setMetaKeyword] = useState('');
    const [meta_description, setMetaDescription] = useState('');
    const [cityConfigFAQ, setCityConfigFAQ] = useState([]);
    const [breadcrumbName, setBreadcrumbName] = useState('');

    const { id, city } = useCustomParams();
    // const { id } = useParams();
    // const { city } = useParams();

    const location = useLocation();

    const currentURL = window.location.origin + location.pathname
    
    useEffect(() => {       
        const extractWordBetweenBhkAndIn = (id) => {
            const matchWithoutBhk = id.match(/([\w-]+)-in-([\w-]+)/);
            if (matchWithoutBhk) {
                return { firstWord: matchWithoutBhk[1], secondWord: matchWithoutBhk[2], type: 'non-bhk' };
            }
            return null;
        };

        const fetchCityCFAQ = async (secondWord, firstWord) => {
            try {
                const faqResponse = await axiosInstance.get(`subCityFaqs/getSubCityFAQByCityAndType/${secondWord}/${firstWord}`);
                const filteredFAQ = faqResponse.data.filter(faq => faq.status === true);
                setCityConfigFAQ(filteredFAQ);
            } catch (err) {

            }
        }

        const fetch = async (id) => {
            try {
                const response = await fetchProjectsBySubCity(id, city);
                const formatBreadcrumbName = (id) => {
                    const val = id
                    .split('-')
                    .map(word => {
                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                    })
                    .join(' ');
                    if (typeof val.slice(-1) === 'string' && !isNaN(parseInt(val.slice(-2, -1)))) {
                        console.log(val)
                        return val.slice(0,-1) + val.slice(-1).toUpperCase();
                    }
                    
                    return val;
                    // return id
                    //     .split('-')
                    //     .map(word => {
                    //         return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                    //     })
                    //     .join(' ');
                };

                const formattedName = formatBreadcrumbName(id);
                setBreadcrumbName(formattedName);
                setConfigurationDetails(response.subCityInfo[0].data[0] ? response.subCityInfo[0].data : ' ');
                const activeProjects = response.project ? response.project.filter(project => project.status === true) : [];
                setCommercialProjects(activeProjects);
                setMetaTitle(response.subCityInfo[0].data[0].metaTitle);
                setMetaKeyword(response.subCityInfo[0].data[0].metaKeyword);
                setMetaDescription(response.subCityInfo[0].data[0].metaDescription);
                // console.log(response)

                if(response.subCityInfo[0].data[0].length === 0 && response.projects.length === 0){
                    navigate('/')
                }

                const metaTitle = response.subCityInfo[0].data[0] && response.subCityInfo[0].data.length > 0 && response.subCityInfo[0].data[0].metaTitle !== ' '? response.subCityInfo[0].data[0].metaTitle : 'Best Real Estate Consultancy Company in India | Star Estate';
                const metaKeyword = response.subCityInfo[0].data[0] && response.subCityInfo[0].data.length > 0 && response.subCityInfo[0].data[0].metaKeyword !== ' '? response.subCityInfo[0].data[0].metaKeyword : 'Best Real Estate Consultancy Company in India | Star Estate';
                const metaDescription = response.subCityInfo[0].data[0] && response.subCityInfo[0].data.length > 0 && response.subCityInfo[0].data[0].metaDescription !== ' '? response.subCityInfo[0].data[0].metaDescription : 'Best Real Estate Consultancy Company in India | Star Estate';
                let schema = response.subCityInfo[0].data[0] && response.subCityInfo[0].data.length > 0 && response.subCityInfo[0].data[0].schema !== ' '? response.subCityInfo[0].data[0].schema : {};
                if (typeof schema === "string") {
                    try {

                        schema = schema.replace(/[\x00-\x1F\x7F-\x9F]/g, "");
                        schema = schema.replace(/\\n/g, " ").replace(/\\r/g, " ").replace(/\\t/g, " ").replace(/\\"/g, '"');

                        schema = JSON.parse(schema);
                    } catch (error) {
                        // console.error("Failed to parse sanitized schema:", error);
                    }
                }
                document.title = metaTitle;

                let metaKeywordTag = document.querySelector("meta[name='keywords']");
                if (metaKeywordTag) {
                    metaKeywordTag.setAttribute("content", metaKeyword);
                } else {
                    metaKeywordTag = document.createElement("meta");
                    metaKeywordTag.name = "keywords";
                    metaKeywordTag.content = metaKeyword;
                    document.head.appendChild(metaKeywordTag);
                }

                let metaDescriptionTag = document.querySelector("meta[name='description']");
                if (metaDescriptionTag) {
                    metaDescriptionTag.setAttribute("content", metaDescription);
                } else {
                    metaDescriptionTag = document.createElement("meta");
                    metaDescriptionTag.name = "description";
                    metaDescriptionTag.content = metaDescription;
                    document.head.appendChild(metaDescriptionTag);
                }
                const schemaScript = document.getElementById("project-schema");
                if (schemaScript) {
                    schemaScript.textContent = JSON.stringify(schema);
                } else {
                    const newSchemaScript = document.createElement("script");
                    newSchemaScript.type = "application/ld+json";
                    newSchemaScript.id = "project-schema";
                    newSchemaScript.textContent = JSON.stringify(schema);
                    document.head.appendChild(newSchemaScript);
                }
                
                const result = await extractWordBetweenBhkAndIn(id);
                if (result) {
                    const { firstWord, secondWord, type } = result;
                    
                        // console.log(secondWord, firstWord);
                        await fetchCityCFAQ(secondWord, firstWord);
                   
                } else {
                    navigate('/')
                    // console.log('No valid pattern found');
                }
            } catch {
                navigate('/');
            } finally { }
        };

        if (id !== undefined) {
            fetch(id)
        }
    }, [id, city]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const [showMore, setShowMore] = useState(false);

    const toggleReadMore = () => {
        setShowMore(!showMore);
    };

    const modalRef = useRef(null);

    const openDetailModal = () => {
        if (modalRef.current) {
            modalRef.current.classList.add("active");
        }
    };
    const closeDetailModal = () => {
        if (modalRef.current) {
            modalRef.current.classList.remove("active");
        }
    };

    const [cities, setCities] = useState([]);
    const [formData, setFormData] = useState({
        projectName: '',
        cityLocation: '',
        projectConfiguration: '',
        projectPrice: '',
        propertyType: id === 'commercial' ? 'commercial' : 'residential'
    });
    useEffect(() => {
        fetchCities().then(data => setCities(data)).catch();
    }, [formData.propertyType, formData.cityLocation]);
    
    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const parsePrice = (price) => {
            if (!price) return '';
            const cleanPrice = price.replace(/[^0-9.]/g, '');
            const numericPrice = parseFloat(cleanPrice);
            if (price.toLowerCase().includes('lakh')) {
                return numericPrice * 100000;
            }
            if (price.toLowerCase().includes('cr')) {
                return numericPrice * 10000000;
            }
            return isNaN(numericPrice) ? '' : numericPrice;
        };

        try {
            let minPrice = '';
            let maxPrice = '';

            if (formData.projectPrice.includes('-')) {
                [minPrice, maxPrice] = formData.projectPrice.split('-').map(price => parsePrice(price.trim()));
                if (parseFloat(maxPrice) === 10000000) {
                    maxPrice = 10000000;
                }
            } else {
                const singlePrice = parsePrice(formData.projectPrice.trim());

                if (singlePrice === 10000000) {
                    minPrice = 0;
                    maxPrice = 10000000;
                } else {
                    minPrice = maxPrice = singlePrice;
                }
            }
            
            let propertyType = '';

            if (id === 'commercial' || id === 'residential') {
                propertyType = id;
            } 
            else if (city) {
                propertyType = city;
            }
            const queryParams = new URLSearchParams({
                propertyType: propertyType || undefined,
                propertyLocation: formData.cityLocation,
                minPrice: minPrice || undefined,
                maxPrice: maxPrice || undefined 
            }).toString();
            navigate(`/projects?${queryParams}`);
        } catch (error) {
            // console.error('Error submitting search:', error);
        }
    };

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div>
            <Helmet>
                {/* <title>{configurationDetails.metaTitle}</title>
                <meta name="description" content={`${configurationDetails.metaDescription}`} />
                <meta name="keywords" content={`${configurationDetails.metaKeyword}`} /> */}
                <meta name="twitter:title" content={meta_title}/>
                <meta name="twitter:description" content={meta_description}/>   
                <meta property="og:title" content={meta_title}/>
                <meta property="og:description" content={meta_description}/>
                <meta property="og:url" content={currentURL}/>
                <link rel="canonical" href={currentURL}/>
                <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
                <meta property="og:image" content="https://www.starestate.com/assets/images/banner-all-projects.jpg"/>
            </Helmet>

            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 540px)" srcSet="/assets/images/banner-all-projects-m.jpg" />
                    <img src="/assets/images/banner-all-projects.jpg" className="h-100 object-cover object-position-bottom" alt="Star Estate" />
                </picture>
                <div className="bannerContainer">
                    <div className="container-lg">
                        <div className="search-filter">
                            <div className="filter-form">
                                <form id="categoryfilter" encType="multipart/form-data" onSubmit={handleSubmit}>
                                    <div className="row gx-2 gap-form-row">
                                        <div className="col-md-10">
                                            <div className="inner">
                                                <div className="row g-0">
                                                    <div className="col mb-0 form-group">
                                                        <select
                                                            name="cityLocation"
                                                            id="cityLocation"
                                                            className="form-control bg-white my-0"
                                                            value={formData.cityLocation}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Project Location</option>
                                                            {cities.map(city => (
                                                                <option key={city._id} value={city.slugURL}>
                                                                    {city.location}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col mb-0 form-group">
                                                        <select
                                                            name="projectPrice"
                                                            id="projectPrice"
                                                            className="form-control bg-white my-0"
                                                            value={formData.projectPrice}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Budget</option>
                                                            <option value="10000000">UpTo 1 Cr.</option>
                                                            <option value="10000000-30000000">1 - 3 Cr.</option>
                                                            <option value="30000000-50000000">3 - 5 Cr.</option>
                                                            <option value="50000000">Above 5 Cr.</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 readmore w-auto mt-0">
                                            <input type="hidden" name="projectfltr" value="active" />
                                            <button className="button w-100 h-100" type="submit">Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item">
                                <Link to='/projects'>
                                    Projects
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                {breadcrumbName ? breadcrumbName + ' ' + city.toString().charAt(0).toUpperCase()+ city.toString().slice(1): ''}
                            </li>

                        </ol>
                    </div>
                </div>
            </div>

            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-auto">
                        <h1 className="h3 mb-0 text-center">
                            {breadcrumbName + ' ' + city.toString().charAt(0).toUpperCase()+ city.toString().slice(1)}
                        </h1>
                    </div>
                    {configurationDetails.map((details) => {
                        const displayContent = details.briefContent;
                        return (
                            <div key={details._id} className="brief-page-content mb-3 text-lg-center">
                                <React.Fragment>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: showMore
                                                ? displayContent
                                                : displayContent
                                        }}
                                    />
                                    <div className='readmore mx-auto mt-3'>
                                        <button onClick={openDetailModal} className="button">
                                            {showMore ? 'Read less' : 'Read more'}
                                        </button>
                                    </div>
                                </React.Fragment>
                            </div>
                        );
                    })}
                    <div ref={modalRef} className="projectOverview-modal">
                        <div className="inner">
                            <div className="projectModal-header">
                                <h6 className="mb-0">City Details</h6>
                                <button className="projectOverview-close" onClick={closeDetailModal}>
                                    &times;
                                </button>
                            </div>
                            {configurationDetails.length > 0 ? (
                                configurationDetails.map((item, index) => (
                                    <div key={index} className="projectOverview-details scroller">
                                        <div dangerouslySetInnerHTML={{ __html: item.ctcontent }} />
                                    </div>
                                ))
                            ) : (
                                <p>No data available</p>
                            )}
                        </div>
                    </div>
                    <div className="row gap-row">
                        {(
                            id && commercialProjects.length) > 0 ? (
                            commercialProjects.map(project => (
                                <div key={project._id} className="col-lg-4 col-sm-6 project_box">
                                    <a href={`/${project.slugURL}`} target="_blank" rel="noopener noreferrer" className="project_box_inner">
                                        <div className="Project_box_img">
                                            <div className="reraBox position-absolute">
                                                <div className="qr_img"><img src={`${axiosInstance.defaults.globalURL}${project.rera_qr}`} alt="QR Code" /></div>
                                                <div className="rera_num">
                                                    <small className="mb-0"><strong className="text-primary">Projects RERA No: </strong>{project.rera_no}<br />
                                                        <small className="small text-primary"><i className="fa fa-link"></i> {project.reraWebsite}</small></small>
                                                </div>
                                            </div>
                                            <div className="img-fluid"><img src={`${axiosInstance.defaults.globalURL}${project.project_thumbnail}`} alt={project.name} /></div>
                                        </div>
                                        <div className="project_box_details">
                                            <div className="project_developer_detail">
                                                <h4 className="mb-0 project_name">{project.projectName}</h4>
                                                <h6 className="mb-0 project_price">
                                                    {project.projectPrice.trim().toLowerCase() === 'price on request' || project.projectPrice.trim().toLowerCase() === 'revealing soon'
                                                        ? `${project.projectPrice.trim()}`
                                                        : <>
                                                            <i className="fa fa-indian-rupee-sign"></i>{project.projectPrice}*
                                                        </>}
                                                </h6>
                                            </div>
                                            <div className="project_status_detail">
                                                <span className="project_box_location"><i className="fa fa-map-marker-alt"></i>{project.projectAddress}</span>
                                                <span className="project_box_status"><i className="fa-brands fa-font-awesome"></i> {Array.isArray(project.project_status)
                                                    ? project.project_status.join(', ')
                                                    : project.project_status}</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))
                        ) 
                        : ('')
                        }
                    </div>
                </div>
            </div>
            {cityConfigFAQ.length > 0 && (
                <div className="w-100 padding bg-gray-gradient-box section-faq">
                    <div className="container-lg">
                        <div className="heading mx-auto text-center">
                            <h2 className="mb-0">FAQs</h2>
                        </div>
                        <div className="">
                            <div className="accordion-wrapper" id="myAccordion">
                                {cityConfigFAQ.map((faq, index) => {
                                    const faqIndex = index + 1;
                                    const isActive = activeIndex === faqIndex;
                                    return (
                                        <div className="accordion-item" key={faqIndex}>
                                            <div className="accordion-header" id={`heading${faqIndex}`}>
                                                <button
                                                    className={`accordion-button ${isActive ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => handleToggle(faqIndex)}
                                                    aria-expanded={isActive ? "true" : "false"}
                                                    aria-controls={`collapse${faqIndex}`}
                                                >
                                                    <span className='text-primary'>Q{faqIndex}:</span> <div dangerouslySetInnerHTML={{ __html: faq.faqQuestion }} />
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                            <div
                                                id={`collapse${faqIndex}`}
                                                className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
                                                aria-labelledby={`heading${faqIndex}`}
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <div className="card-body">
                                                        <div dangerouslySetInnerHTML={{ __html: faq.faqAnswer }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default SubCityProjects