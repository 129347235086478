import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Blogs() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [itemsPerPage] = useState(9);
    const location = useLocation();

    const currentURL = window.location.origin + location.pathname;

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axiosInstance.get('/blogs/getBlog');
                const filteredBlogs = response.data.filter(blog => blog.status === true && blog.blogsCategory === 'blog');
                setBlogs(filteredBlogs);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch blogs');
                setLoading(false);
            }
        };
        fetchBlogs();
    }, []);

    // Logic for paginating the blogs
    // const indexOfLastBlog = currentPage * itemsPerPage;
    // const indexOfFirstBlog = indexOfLastBlog - itemsPerPage;
    // const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

    // const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <Helmet>
                <title>Blogs by Star Estate | Residential & Commercial Property in Delhi NCR</title>
                <meta name="description" content="Learn about the events, rewards, and recognition ceremonies hosted by Star Estate to honor excellence in real estate services and partnerships." />
                <meta name="keywords" content="Star Estate events, real estate rewards, recognition real estate, Star Estate recognition, property awards events, real estate partnerships rewards" />
                <meta name="twitter:title" content="Best Real Estate Consultancy Company in India | Star Estate" />
                <meta name="twitter:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now." />
                <meta property="og:title" content="Best Real Estate Consultancy Company in India | Star Estate" />
                <meta property="og:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now." />
                <meta property="og:url" content={currentURL} />
                <link rel="canonical" href={currentURL} />
                <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp" />
                <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp" />
            </Helmet>

            <div className="insideBanner">
                <picture>
                    <source
                        media="(min-width: 992px)"
                        srcSet="assets/images/blog.jpg"
                    />
                    <source
                        media="(min-width: 768px)"
                        srcSet="assets/images/blog-m.jpg"
                    />
                    <img
                        src="assets/images/blog-m.jpg"
                        className="h-100 object-cover object-position-bottom rounded"
                        alt="Star Estate"
                    />
                </picture>
            </div>

            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item">Media</li>
                            <li className="breadcrumb-item active">Blogs</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h3 className="mb-0">Blogs</h3>
                    </div>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading blogs...</span>
                            </div>
                            <span className="ml-2">Loading blogs...</span>
                        </div>
                    ) : error ? (
                        <p>{error}</p>
                    ) : (
                        <div className="row g-4 gap-row">
                            {blogs.map((blog, index) => (
                                <div key={index} className="col-lg-4 col-sm-6 blogBox">
                                    <div className="inner common-border">
                                        <div className="img-fluid">
                                            <Link to={`/blogs/${blog.slugURL}`}>
                                                <img src={`${axiosInstance.defaults.globalURL}${blog.blogsImage}`} alt={blog.blogsName || 'Blog Image'} />
                                            </Link>
                                        </div>
                                        <div className="blog-details">
                                            <ul className="list-inline">
                                                <li><i className="fa fa-calendar-alt text-primary"></i> <span>{new Date(blog.blogsDate).toLocaleDateString('en-GB').replace(/\//g, '-')}</span></li>
                                                <li><i className="fa fa-tag text-primary"></i> <span>{blog.blogsBy}</span></li>
                                            </ul>
                                            <Link to={`/blogs/${blog.slugURL}`} className="h6">{blog.blogsName}</Link>
                                            <div className="continue-reading"><Link to={`/blogs/${blog.slugURL}`}>Continue Reading</Link></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
 {/* Pagination controls */}
 {/* <div className="pagination mt-5 flex justify-end pr-4">
    <button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
        className="btn btn-primary readmore"
    >
        Previous
    </button>
    <span className="mx-2 self-center">Page {currentPage}</span>
    <button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage * itemsPerPage >= blogs.length}
        className="btn btn-primary readmore"
    >
        Next
    </button>
</div> */}
                </div>
                
                   

            </div>
            
        </div>
    );
}

export default Blogs;
