import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import './App.css';
import PropTypes from 'prop-types';
import axiosInstance from '../views/utils/axiosInstance';
import { Helmet } from 'react-helmet';
import Swiper from 'swiper';


export const sendChannelData = async (formData) => {
  try {
      const response = await axiosInstance.post(`/channelPartner/addChannelPartner`, formData);
      return response;
  } catch (error) {
      console.error('Error sending data:', error);
      throw error;
  }
};

const CPRegistration = () => {
  const [formData, setFormData] = useState({
    broker_name: '',
    email: '',
    phoneNumber: '',
    company_name: '',
    registration_no: '',
  });

  const location = useLocation();

  const currentURL = window.location.origin + location.pathname

  const [successMessage, setSuccessMessage] = useState('');

  // useEffect(() => {
  //   const body = document.body;
  //   if (showModal) {
  //     body.classList.add('blur-background');
  //     // Reset form data when modal opens
  //     setFormData({
  //       broker_name: '',
  //       email: '',
  //       phoneNumber: '',
  //       company_name: '',
  //       registration_no: '',
  //     });
  //   } else {
  //     body.classList.remove('blur-background');
  //   }
  // }, [showModal]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async  (e) =>{
    e.preventDefault();
    await sendChannelData(formData)
    setSuccessMessage('Form submitted successfully!');
    setFormData({
      broker_name: '',
      email: '',
      phoneNumber: '',
      company_name: '',
      registration_no: '',
    });
      setTimeout(() => {
        setSuccessMessage(''); // Clear message after some time
        // closeModal(); // Close the modal after showing the message
      }, 2000);
  };

  // const modalStyle = {
  //   display: showModal ? 'block' : 'none',
  //   paddingRight: showModal ? '18px' : '0'
  // };

  // const contentStyle = {
  //   backgroundImage: 'url(..assets/images/card-bg.jpg)', // Replace with your image path
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   color: 'black', // Text color updated to black
  //   padding: '20px', // Added padding to ensure content doesn't touch the edges
  // };

  // const titleStyle = {
  //   textAlign: 'left', // Aligns the title to the left
  //   margin: 0 // Optional: Adjusts margin to fit design
  // };

  new Swiper('.logo-slider', {
    slidesPerView: "auto",
    spaceBetween: 10,
    loop: true,
    speed: 6000,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
      disableOnInteraction: false
    },
    // breakpoints: {
    //     280: { slidesPerView: 2 },
    //     375: { slidesPerView: 3 },
    //     640: { slidesPerView: 4},
    //     1200: { slidesPerView: 6},
    //     1400: { slidesPerView: 7},
    //     1900: { slidesPerView: 8},
    // }
  });

  return (
    <>
       <Helmet>
          <title>Best Real Estate Consultancy Company in India | Star Estate</title>
          <meta name="description" content="Star Estate is the Best Real Estate Consultancy Company In India. Browse Extensive Range Of Luxury Residential and Commercial Properties in India. Explore Now." />
          <meta name="keywords" content="Real Estate Consultancy Company, Real Estate Consultancy Company in India, Best Real Estate Consultancy,  Real Estate Company, Real Estate company in India, " />
          <meta name="twitter:title" content="Best Real Estate Consultancy Company in India | Star Estate"/>
          <meta name="twitter:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>   
          <meta property="og:title" content='Best Real Estate Consultancy Company in India | Star Estate'/>
          <meta property="og:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>
          <meta property="og:url" content={currentURL}/>
          <link rel="canonical" href={currentURL}/>
          <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
          <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"/>
      </Helmet>
      <div className="insideBanner">
        <picture>
            <source 
                media="(min-width: 992px)" 
                srcSet="assets/images/banner-per.jpg" 
            />
            <source 
                media="(min-width: 768px)" 
                srcSet="assets/images/banner-per-m.jpg" 
            />
            <img 
                src="assets/images/banner-per-m.jpg" 
                className="h-100 object-cover object-position-bottom rounded" 
                alt="Star Estate" 
            />
        </picture>
      </div>

      <div className="w-100">
          <div className="container-lg">
              <div className="breadcrumbContainer" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                      <li className="breadcrumb-item active">Star Partner Connect</li>
                  </ol>
              </div>
          </div>
      </div>
      <div className="w-100 padding">
          <div className="container-lg">
            <div className="heading mx-auto">
                <h3 className="mb-0">Star Partner Connect</h3>
            </div>
            <div className='p-4 bg-gray-gradient-box'>
                  <form className="form-container" onSubmit={handleSubmit}>                
                    <span className="status mb-0 text-warning">{successMessage}</span>
                    <div className="row gy-4 gx-3">
                      <div className="col-sm-4">
                        <label htmlFor="broker_name">
                          Name<sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control bg-white"
                          id="broker_name"
                          name="broker_name"
                          value={formData.broker_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="email">
                          Email<sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="email"
                          className="form-control bg-white"
                          name="email"
                          id="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="phoneNumber">
                          Mobile<sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="tel"
                          className="form-control bg-white"
                          name="phoneNumber"
                          id="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                          required
                          pattern="\d{10}"
                          title="Phone number must be exactly 10 digits"
                        />
                        <div className="invalid-feedback">
                          Phone number must be exactly 10 digits.
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="company_name">
                          Company Name<sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control bg-white"
                          name="company_name"
                          id="company_name"
                          value={formData.company_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="registration_no">
                          Present City<sup className="text-danger">*</sup>
                        </label>
                        <select
                          className="form-control bg-white"
                          name="registration_no"
                          id="registration_no"
                          value={formData.registration_no}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select City</option>
                          <option value="Agra">Agra</option>
                          <option value="Ahmedabad">Ahmedabad</option>
                          <option value="Ayodhya">Ayodhya</option>
                          <option value="Bangalore">Bangalore</option>
                          <option value="Chennai">Chennai</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Faridabad">Faridabad</option>
                          <option value="Ghaziabad">Ghaziabad</option>
                          <option value="Greater Noida">Greater Noida</option>
                          <option value="Gurugram">Gurugram</option>
                          <option value="Hyderabad">Hyderabad</option>
                          <option value="Kotkata">Kolkata</option>
                          <option value="Lucknow">Lucknow</option>
                          <option value="Mathura">Mathura</option>
                          <option value="Mumbai">Mumbai</option>
                          <option value="Noida">Noida</option>
                          <option value="Pune">Pune</option>
                        </select>
                      </div>

                      <div className="col-sm-1 readmore mt-3">
                        <input type="hidden" name="CP_action" value="active" />
                        <button type="submit" className="button">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
            </div>
          </div>
      </div>

      <div className="w-100 padding bg-lightgray">
        <div className="container-lg">
          <div className="heading mx-auto text-center">
              <h3>Why Partner with Us?</h3>
          </div>
          <div className="hm-project-card-wrapper">
              <div className="row g-3">
                <div className="col-lg-3 col-sm-6 project-card cp-card"> 
                  <div className="cp-card--inner">
                    <div className='inner'>
                      <div className="crd--display">
                        <div className='img-fluid'><img src="../assets/images/icons/Lead-Security.png" alt="Lead Security" /></div>
                        <h5 className='cp-card-title text-texture'>Lead Security</h5>
                      </div>
                      <div className="card--hover">
                        <h5 className='cp-card-title text-texture'>Lead Security</h5>
                        <p className='mb-0'>An advanced and integrated CRM platform to track real-time updates on your leads.</p>
                      </div>
                      </div>
                    <div className="card--border"></div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 project-card cp-card"> 
                  <div className="cp-card--inner">
                    <div className='inner'>
                      <div className="crd--display">
                        <div className='img-fluid'><img src="../assets/images/icons/Timely-Payouts.png" alt="Timely Payouts" /></div>
                        <h5 className='cp-card-title text-texture'>Timely Payouts</h5>
                      </div>
                      <div className="card--hover">
                        <h5 className='cp-card-title text-texture'>Timely Payouts</h5>
                        <p className='mb-0'>Prompt disbursal of payments reflects our commitment to professional excellence.</p>
                      </div>
                      </div>
                    <div className="card--border"></div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 project-card cp-card"> 
                  <div className="cp-card--inner">
                    <div className='inner'>
                      <div className="crd--display">
                        <div className='img-fluid'><img src="../assets/images/icons/Assistance.png" alt="Home Loan Assistance" /></div>
                        <h5 className='cp-card-title text-texture'>Home Loan Assistance</h5>
                      </div>
                      <div className="card--hover">
                        <h5 className='cp-card-title text-texture'>Home Loan Assistance</h5>
                        <p className='mb-0'>One-stop solution for documentation leading to hassle-free real estate transactions.</p>
                      </div>
                      </div>
                    <div className="card--border"></div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 project-card cp-card"> 
                  <div className="cp-card--inner">
                    <div className='inner'>
                      <div className="crd--display">
                        <div className='img-fluid'><img src="../assets/images/icons/Global-Network.png" alt="Global Network" /></div>
                        <h5 className='cp-card-title text-texture'>Global Network</h5>
                      </div>
                      <div className="card--hover">
                        <h5 className='cp-card-title text-texture'>Global Network</h5>
                        <p className='mb-0'>Connect online and offline with professionals pan India to know the latest market scenario.</p>
                      </div>
                      </div>
                    <div className="card--border"></div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      
      <div className="w-100 padding">
        <div className="container-lg">
          <div className="heading mx-auto text-center">
              <h3>Authorized Channel Partner for</h3>
          </div>
          <div className="swiper logo-slider w-100">
            <div className="swiper-wrapper">
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/dlf.svg" alt="DLF" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/godrej.svg" alt="Godrej Properties" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/lodha.svg" alt="Lodha" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/prestige.svg" alt="Prestige" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/tvs.svg" alt="TVS" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/ats-homekraft.svg" alt="ATS Homekraft" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/m3m.svg" alt="M3M" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/nbcc.png" alt="NBCC" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/gaurs.svg" alt="Gaursons" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/max-estates.svg" alt="Max Estates" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/adani-realty.svg" alt="Adani Realty" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/dlf.svg" alt="DLF" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/godrej.svg" alt="Godrej Properties" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/lodha.svg" alt="Lodha" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/prestige.svg" alt="Prestige" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/tvs.svg" alt="TVS" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/ats-homekraft.svg" alt="ATS Homekraft" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/m3m.svg" alt="M3M" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/nbcc.png" alt="NBCC" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/gaurs.svg" alt="Gaursons" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/max-estates.svg" alt="Max Estates" />
                  </div>
              </div>
              <div className="swiper-slide logoBox h-auto">
                  <div className="inner h-100">
                      <img src="../assets/images/logos/adani-realty.svg" alt="Adani Realty" />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

// CPRegistration.propTypes = {
//   showModal: PropTypes.bool.isRequired,
//   closeModal: PropTypes.func.isRequired,
//   title: PropTypes.string
// };

// CPRegistration.defaultProps = {
//   title: 'Fill the form to register as a Channel Partner'
// };

export default CPRegistration;
