import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import Typed from "typed.js";
import { fetchCategories, fetchCities, fetchProjects } from "../../../apis/home-page-api";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";

export default function Banner() {
  const navigate = useNavigate();

  // State management
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [projects, setProjects] = useState([]);
  const [formData, setFormData] = useState({
    projectName: "",
    cityLocation: "",
    projectConfiguration: "",
    projectPrice: "",
    property_type: "",
  });

  const [index, setIndex] = useState(0);
  const [filter, setFilter] = useState([]);

  const imageSources = [
    {
      mobile: "../assets/images/homebanner/banner-apartments-m.webp",
      hd: "../assets/images/homebanner/banner-apartments-hd.webp",
      desktop: "../assets/images/homebanner/banner-apartments.webp",
    },
    {
      mobile: "../assets/images/homebanner/banner-villa-m.webp",
      hd: "../assets/images/homebanner/banner-villa-hd.webp",
      desktop: "../assets/images/homebanner/banner-villa.webp",
    },
    {
      mobile: "../assets/images/homebanner/banner-mall-m.webp",
      hd: "../assets/images/homebanner/banner-mall-hd.webp",
      desktop: "../assets/images/homebanner/banner-mall.webp",
    },
    {
      mobile: "../assets/images/homebanner/banner-office-m.webp",
      hd: "../assets/images/homebanner/banner-office-hd.webp",
      desktop: "../assets/images/homebanner/banner-office.webp",
    },
  ];

  // Typed.js for text animation
  useEffect(() => {
    const typed = new Typed("#typed", {
      strings: ["Apartment", "Villa", "Retail Space", "Office Space"],
      typeSpeed: 160,
      backDelay: 1500,
      backSpeed: 50,
      loop: true,
      preStringTyped: (arrayPos, self) => {
        const typedText = self.strings[arrayPos].trim();
        const items = ["Apartment", "Villa", "Retail Space", "Office Space"];
        const foundIndex = items.indexOf(typedText);
        if (foundIndex !== -1) {
          setIndex(foundIndex);
        }
      },
    });
    return () => {
      typed.destroy();
    };
  }, []);

  // Fetch categories and cities on mount
  useEffect(() => {
    fetchCategories().then(setCategories).catch(console.error);
    fetchCities().then(setCities).catch(console.error);
  }, []);

  // Fetch filters
  useEffect(() => {
    const fetchFilter = async () => {
      try {
        const response = await axiosInstance.get(`addProjects/projects`);
        setFilter(response.data);
      } catch (error) {
        console.error("Failed to fetch filters", error);
      }
    };
    fetchFilter();
  }, []);

  // Image preloading
  useEffect(() => {
    imageSources.forEach((source) => {
      Object.values(source).forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    });
  }, []);

  // Scroll functionality
  useEffect(() => {
    const scrollDown = document.querySelector('.scrollDown');
    const scrollToElement = document.querySelector('.scrollto');
  
    if (scrollDown && scrollToElement) {
      const handleScroll = () => {
        window.scrollTo({
          top: scrollToElement.offsetTop,
          behavior: 'smooth',
        });
      };
  
      scrollDown.addEventListener('click', handleScroll);
  
      return () => {
        scrollDown.removeEventListener('click', handleScroll);
      };
    }
  }, []);
  

  // Handle form changes
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "property_type" && value) {
      fetchProjects(value).then(setProjects).catch(console.error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const parsePrice = (price) => {
      if (!price) return "";
      const cleanPrice = price.replace(/[^0-9.]/g, "");
      const numericPrice = parseFloat(cleanPrice);
      if (price.toLowerCase().includes("lakh")) {
        return numericPrice * 100000;
      }
      if (price.toLowerCase().includes("cr")) {
        return numericPrice * 10000000;
      }
      return isNaN(numericPrice) ? "" : numericPrice;
    };

    try {
      let minPrice = "";
      let maxPrice = "";

      if (formData.projectPrice.includes("-")) {
        [minPrice, maxPrice] = formData.projectPrice.split("-").map(price => parsePrice(price.trim()));
      } else {
        const singlePrice = parsePrice(formData.projectPrice.trim());
        minPrice = maxPrice = singlePrice;
      }

      const queryParams = new URLSearchParams({
        projectType: formData.property_type,
        propertyLocation: formData.cityLocation,
        minPrice,
        maxPrice,
      }).toString();

      navigate(`/projects?${queryParams}`);
    } catch (error) {
      console.error("Error submitting search:", error);
    }
  };

  return (
      <div
        id="banner"
        className="carousel slide carousel-fade banner"
        data-bs-pause="false"
        data-bs-ride="carousel"
      >
        <Carousel indicators={false} controls={false} wrap={true} activeIndex={index} className="h-100">
          {imageSources.map((source, i) => (
            <Carousel.Item key={i}>
              <picture>
                <source media="(max-width: 540px)" srcSet={source.mobile} fetchpriority="high" />
                <source media="(max-width: 1400px)" srcSet={source.hd} fetchpriority="high" />
                <img
                  src={source.desktop}
                  alt="Star Estate"
                  className="d-block w-100 carousel-img"
                  fetchpriority="high"
                />
              </picture>
              <span className="typing-text-in-carousel">
                {["Apartment", "Villa", "Mall", "Office Space"][i]}
              </span>
            </Carousel.Item>
          ))}
        </Carousel>
        <img src="assets/images/gow-logo.png" className="gow-logo" alt="GOW Logo" />

        <div className="banner-overlay">
          <div className="bannerContainer">
            <div className="container-lg">
              <div className="search-filter">
                <span className="h4">
                  You deserve the best <span id="typed">Apartment</span>
                  <span className="typed-cursor" aria-hidden="true"></span>
                </span>
                <div className="filter-form">
                  <form id="categoryfilter" onSubmit={handleSubmit}>
                    <div className="row gx-2 gap-form-row">
                      <div className="col-md-10">
                        <div className="inner">
                          <div className="row g-0">
                            <div className="col mb-0 form-group">
                              <select
                                name="property_type"
                                id="property_type"
                                className="form-control bg-white my-0"
                                value={formData.property_type}
                                onChange={handleChange}
                              >
                                <option value="">Property Type</option>
                                {categories.map((category) => (
                                  <option key={category._id} value={category.slugURL}>
                                    {category.category}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col mb-0 form-group">
                              <select
                                name="cityLocation"
                                id="cityLocation"
                                className="form-control bg-white my-0"
                                value={formData.cityLocation}
                                onChange={handleChange}
                              >
                                <option value="">Project Location</option>
                                {cities.map((city) => (
                                  <option key={city._id} value={city.slugURL}>
                                    {city.location}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col mb-0 form-group">
                              <select
                                name="projectPrice"
                                id="projectPrice"
                                className="form-control bg-white my-0"
                                value={formData.projectPrice}
                                onChange={handleChange}
                              >
                                <option value="">Budget</option>
                                <option value="10000000">UpTo 1 Cr.</option>
                                <option value="10000000-30000000">1 - 3 Cr.</option>
                                <option value="30000000-50000000">3 - 5 Cr.</option>
                                <option value="50000000">Above 5 Cr.</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="readmore w-auto mt-0 h-100">
                          <button className="button w-100 h-100" type="submit">
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="filter-property-type flex-column flex-sm-row">
                    <Link to="/projects/new-launch" target="_blank" rel="noopener noreferrer">
                      New Launches
                    </Link>
                    <Link to="/projects/commercial" target="_blank" rel="noopener noreferrer">
                      Commercial Properties
                    </Link>
                    <Link to="/projects/residential" target="_blank" rel="noopener noreferrer">
                      Residential Properties
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="scrollDown d-none d-md-block">Scroll<br /><i className="fa fa-chevron-down"></i></div>
        </div>
    )
  }
    
       
