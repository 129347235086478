import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

const ThankYou = () => {
  const location = useLocation();

  const currentURL = window.location.origin + location.pathname
  useEffect(() => {
    // Check if the form was submitted
    const wasFormSubmitted = localStorage.getItem('formSubmitted');

    if (wasFormSubmitted) {
      // Reload the page once
      window.location.reload();
      // Clear the item from localStorage so it doesn't reload again
      localStorage.removeItem('formSubmitted');
    }
  }, []);

  return (
    <> <Helmet>
    <title>Best Real Estate Consultancy Company in India | Star Estate</title>
    <meta name="description" content="Star Estate is the Best Real Estate Consultancy Company In India. Browse Extensive Range Of Luxury Residential and Commercial Properties in India. Explore Now." />
    <meta name="keywords" content="Real Estate Consultancy Company, Real Estate Consultancy Company in India, Best Real Estate Consultancy,  Real Estate Company, Real Estate company in India, " />
    <meta name="twitter:title" content="Best Real Estate Consultancy Company in India | Star Estate"/>
    <meta name="twitter:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>   
    <meta property="og:title" content='Best Real Estate Consultancy Company in India | Star Estate'/>
    <meta property="og:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>
    <meta property="og:url" content={currentURL}/>
                    <link rel="canonical" href={currentURL}/>
                    <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
                    <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"/>
</Helmet>
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light text-center">
      <picture>
        <source media="(max-width: 768px)" srcSet="assets/images/Thank-You-page-image.jpg" />
        <img 
          src="assets/images/Thank-You-page-image.jpg" 
          alt="Thank You Illustration" 
        />
      </picture>
      <Link to="/" className="btn btn-warning btn-lg" style={{ marginTop: '20px', marginBottom: '20px' }}>
        Go to Home
      </Link>
    </div></>
  );
};

export default ThankYou;
