import React, { useEffect, useState } from 'react';
import Banner from "./banner";
import CitywiseContainer from "./cities";
import InsightsTools from "./insight_tools";
import ProjectSlider from "./luxury_projects";
import NewsViews from "./news_views";
import SocialMediaFeed from "./social_media";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function Home() {
    const location = useLocation();

  const currentURL = window.location.origin + location.pathname

    return (          
            <>
                <Helmet>
                    <title>Best Real Estate Consultancy Company in India | Star Estate</title>
                    <meta name="description" content="Star Estate is the Best Real Estate Consultancy Company In India. Browse Extensive Range Of Luxury Residential and Commercial Properties in India. Explore Now." />
                    <meta name="keywords" content="Real Estate Consultancy Company, Real Estate Consultancy Company in India, Best Real Estate Consultancy,  Real Estate Company, Real Estate company in India, " />
                    <meta name="twitter:title" content="Best Real Estate Consultancy Company in India | Star Estate"/>
                    <meta name="twitter:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>   
                    <meta property="og:title" content='Best Real Estate Consultancy Company in India | Star Estate'/>
                    <meta property="og:description" content="Star Estate is the best real estate consultancy company in India. Browse extensive range of luxury residential and commercial properties in India. Explore now."/>
                    <meta property="og:url" content={currentURL}/>
                    <link rel="canonical" href={currentURL}/>
                    <meta name="twitter:image" content="https://www.starestate.com/assets/images/homebanner/banner-mall.webp"/>
                    <meta property="og:image" content="https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp"/>
                    <script type="application/ld+json">
                            {JSON.stringify(
                            {
                                "@context": "https://schema.org",
                                "@type": "Organization",
                                "name": "Best Real Estate Consultancy Company in India | Star Estate",
                                "url": " https://www.starestate.com/",
                                "logo": "https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp",
                                "image": [
                                    " https://www.starestate.com/assets/images/homebanner/banner-office.webp "
                                ],
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": " Riana Towers, 4th Floor, Plot No: 111-112, Sector 136, Noida - 201304 ",
                                    "addressLocality": " Greater Noida, Dist. Gautam Budh Nagar ",
                                    "addressRegion": " Uttar Pradesh ",
                                    "postalCode": " 203104 ",
                                    "addressCountry": "In"
                                },
                                "review": {
                                    "@type": "Review",
                                    "reviewRating": {
                                        "@type": "Rating",
                                        "ratingValue": "4.7",
                                        "bestRating": "333"
                                    },
                                    "author": {
                                        "@type": "Person",
                                        "name": " Star Estate | Top Real Estate Consultancy Firm in India - Vijay Jain "
                                    }
                                },
                                "telephone": "+91 70884 70884",
                                "sameAs": [
                                    "https://https://www.facebook.com/starestate.in/",
                                    "https://https://www.instagram.com/starestate_official/",
                                    "https://www.youtube.com/@starestate",
                                    "https://https://www.linkedin.com/company/star-estate/",
                                    "https://https://www.starestate.com/",
                                    "https://twitter.com/starestate2"
                                ]
                            }
                            )}
                        </script>
                        <script type="application/ld+json">
                            {JSON.stringify(
                             {
                                "@context": "https://schema.org",
                                "@type": "RealEstateAgent",
                                "name": "Star Estate",
                                "url": "https://www.starestate.com/",
                                "logo": "https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp",
                                "description": "Star Estate is the best real estate consultancy company in india. Browse extensive range of luxury residential and commercial properties in India. Explore now.",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "Riana Towers, 4th Floor, Plot No: 111-112, Sector 136,",
                                    "addressLocality": "Noida",
                                    "postalCode": "201304",
                                    "addressCountry": "In"
                                },
                                "telephone": "+9170884 70884",
                                "image": "https://www.starestate.com/assets/images/homebanner/banner-office.webp",
                                "openingHours": [
                                    "Monday-Sunday 9:00-19:00"
                                ],
                                "priceRange": "50Lakh-30cr"
                            }
                            )}
                        </script>
                        <script type="application/ld+json">
                            {JSON.stringify(
                             {
                                "@context": "https://schema.org",
                                "@type": "LocalBusiness",
                                "name": "Best Real Estate Consultancy Company in India | Star Estate",
                                "image": "https://www.starestate.com/static/media/logo-starestate.6297e07e21c140ea9787.webp",
                                "@id": "",
                                "url": "https://www.starestate.com",
                                "telephone": "+91 70884 70884",
                                "priceRange": "Ask For Price",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "Riana Towers, 4th Floor, Plot No: 111-112, Sector 136, Noida - 201304, Greater Noida, Dist. Gautam Budh Nagar. U.P.",
                                    "addressLocality": "Greater Noida",
                                    "postalCode": "203104",
                                    "addressCountry": "IN"
                                },
                                "geo": {
                                    "@type": "GeoCoordinates",
                                    "latitude": 28.4949354,
                                    "longitude": 77.4945979
                                },
                                "openingHoursSpecification": {
                                    "@type": "OpeningHoursSpecification",
                                    "dayOfWeek": [
                                        "Monday",
                                        "Tuesday",
                                        "Wednesday",
                                        "Thursday",
                                        "Friday",
                                        "Saturday",
                                        "Sunday"
                                    ],
                                    "opens": "09:30",
                                    "closes": "19:00"
                                },
                                "sameAs": [
                                    "https://https://www.facebook.com/starestate.in/",
                                    "https://https://www.instagram.com/starestate_official/",
                                    "https://www.youtube.com/@starestate",
                                    "https://https://www.linkedin.com/company/star-estate/",
                                    "https://https://www.starestate.com/",
                                    "https://twitter.com/starestate2"
                                ]
                            }
                            )}
                        </script>
                        <script type="application/ld+json">
                            {JSON.stringify(
                             {
                                "@context": "https://schema.org/",
                                "@type": "WebSite",
                                "name": "Star Estate",
                                "url": "https://www.starestate.com/",
                                "potentialAction": {
                                    "@type": "SearchAction",
                                    "target": "{search_term_string}",
                                    "query-input": "required name=search_term_string"
                                }
                            }
                            )}
                        </script>
                </Helmet>

                <Banner />
                <InsightsTools />
                <ProjectSlider />
                <CitywiseContainer />
                <NewsViews />
                <SocialMediaFeed />
            </>
    );
}
